import Content from "./Content";

const FullWidth = ({ content }) => {    
    return (
        <div className="col-md-12">
            <Content htmldata={content} />
        </div>
    );
};

export default FullWidth;