import Dropdown from './Dropdown';
const MenuItems = ({ items }) => {
    return (items.has_child==1 ? (
          <>
          <li className="has-sub">
                <a href={items.url} target={items.target}>
                    {items.title}
                </a>
                <Dropdown submenus={items.childreen} />
              </li>
          </>
        ) : (          
          <li><a href={items.url} target={items.target}>{items.title}</a></li>
        )
    );
  };
  
  export default MenuItems;