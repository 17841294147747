import jQuery from "jquery";

(function($)
{    
    
	$.extend($.fn, {
        
		simpleLightboxVideo : function()
		{
			var defaults = {
				delayAnimation: 300,
				keyCodeClose: 27
			};

			$.simpleLightboxVideo.vars = $.extend({}, defaults);

			var video = this;

			video.click(function()
			{
                var margintop = 0
				if (window.innerHeight > 540){
					margintop = (window.innerHeight - 540) / 2;
				}
                function YouTubeGetID (url){                    
                    var ID = '';                    
                    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                    if(url[2] !== undefined) {
                        ID = url[2].split(/[^0-9a-z_-]/i);
                        ID = ID[0];
                    }
                    else {
                        ID = url;
                    }
                    return ID;
                }
                
                console.log(YouTubeGetID($(this).attr('href')));

				var ifr = '<iframe src="https://www.youtube.com/embed/'+YouTubeGetID($(this).attr("href"))+'?autoplay=1" width="640" height="480" id="slvj-video-embed" style="border:0;" allow="autoplay; fullscreen"></iframe>';
				var close = '<div id="slvj-close-icon"></div>';
				var lightbox = '<div class="slvj-lightbox" style="margin-top:' + margintop + 'px">';
				var back = '<div id="slvj-back-lightbox">';
				var bclo = '<div id="slvj-background-close"></div>';
				var win = '<div id="slvj-window">';
				var end = '</div></div></div>';

                $(document).find('#slvj-window').remove();
				$('body').append(win + bclo + back + lightbox + close + ifr + end);
				$('#slvj-window').hide();
				$('#slvj-window').fadeIn();

                $('#slvj-close-icon').on("click",function()
				{
					$('#slvj-window').fadeOut($.simpleLightboxVideo.vars.delayAnimation, function()
					{
						$(this).remove();
					});
				});

				$('#slvj-background-close').click(function()
				{
					$('#slvj-window').fadeOut($.simpleLightboxVideo.vars.delayAnimation, function()
					{
						$(this).remove();
					});
				});
				return false;
			});

			return false;
		}
	});
})(jQuery);

(function($)
{
	$.simpleLightboxVideo = function(options, video)
	{
		return $(video).simpleLightboxVideo();
	}
})(jQuery);