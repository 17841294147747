import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Index from './Pages/index';
import About from './Pages/about';
import BlogDetails from './Pages/BlogDetails';
import EventDetails from './Pages/EventDetails';
import Gallery from './Pages/Gallery';
import CategoryBlog from './Pages/CategoryBlog';
import PageNotFound from './Errors/PageNotFound';
import PublicationDetails from './Pages/PublicationDetails';
class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Routes>
                        <Route path='/' exact element={<Index/>} />   
                        <Route path='/:slug' exact element={<About/>} /> 
                        <Route path='/blog/:slug' exact element={<BlogDetails/>} />   
                        <Route path='/events/:slug' exact element={<EventDetails/>} />   
                        <Route path='/gallery/:slug' exact element={<Gallery/>} />   
                        <Route path='/category/:slug' exact element={<CategoryBlog/>} />   
                        <Route path='/publication/:slug' exact element={<PublicationDetails/>} />   
                        <Route path="/*" element={<PageNotFound />} />
                    </Routes>                    
                </div>
            </BrowserRouter>	
		)
	}
	
}

export default Markup;