const Dropdown = ({ submenus }) => {
  return (
    <ul>
      {submenus.map((items, index) => (items.has_child == 1 ? (
        <>
          <li className="has-sub">
            <a href={items.url} target={items.target}>
              {items.title}
            </a>
            <Dropdown submenus={items.childreen} />
          </li>
        </>
      ) : (
        <li key={index}>
          <a href={items.url} target={items.target}>{items.title}</a>
        </li>
      )
      ))}
    </ul>
  );
};

export default Dropdown;