import React, { useState, useEffect } from 'react';
import init from '../../js/main';
import Header1 from '../Layout/Header1';
import Footer from '../Layout/Footer';
import pnf_img from '../../img/404-img.png';

const PageNotFound = () => {
    useEffect(() => {
        setTimeout(function() {
            init();
         }.bind(this), 400);	
    }, []);

    return (
        <div>
            <Header1 />
            <section className="event event03 pt-80 pb-80 p-relative fix">
                <div className="container">
                    <div class="row">
                        <div className="col-lg-12 col-md-12 text-center  wow fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s">
                            <img src={pnf_img} alt="404" />
                            <div className="slider-btn d-block mt-50">
                                <a href={"/"} class="btn ss-btn smoth-scroll">Go To Home Page <i class="fal fa-long-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default PageNotFound;