import React, { Component,useState, useEffect } from 'react';
import * as DOMPurify from 'dompurify';
import { useParams } from "react-router-dom";

import Header1 from '../Layout/Header1';
import Breadcrumb from '../Layout/Breadcrumb';
import FullWidth from '../Layout/FullWidth';
import PageWithSideBar from '../Layout/PageWithSideBar';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';
import getBaseUrl from '../../base/htttp';
import init from '../../js/main';
import PageNotFound from '../Errors/PageNotFound'

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Content from '../Layout/Content';

function Gallery() {    
	const [data, setData] = useState("Loading...");
	const [statusCode, setStatusCode] = useState();
    const [index, setIndex] = useState(-1);
	const [open, setOpen] = React.useState(false);
	const [controls, setControls] = React.useState(true);
	const [playsInline, setPlaysInline] = React.useState(true);
	const [autoPlay, setAutoPlay] = React.useState(false);
	const [loop, setLoop] = React.useState(false);
	const [muted, setMuted] = React.useState(false);
	const [disablePictureInPicture, setDisablePictureInPicture] =
		React.useState(false);
	const [disableRemotePlayback, setDisableRemotePlayback] =
		React.useState(false);
	const [controlsList, setControlsList] = React.useState("noremoteplayback");
	const [crossOrigin, setCrossOrigin] = React.useState("");
	const [preload, setPreload] = React.useState("");

    const pageSlug = useParams();
	const getData = () => {
		fetch(getBaseUrl()+"gallery/"+pageSlug.slug)        
		.then(async (response) => {
			if (response.ok) {
			  return response.json()
			} else if(response.status === 404) {
				setStatusCode(response.status)
				return "";
			} else {
			  console.log('some other error: ' + response.status)
			}
		})
		.then(async (result) => {
			if(result!="" && result.success){
				setData(result.data);
				setTimeout(function() {
					init();
				}.bind(this), 400);												
			}
		})
	}

	useEffect(() => {
		getData();
	}, []);
	if (statusCode === 404) {
		return <PageNotFound />
	}
	return (        
		<div>			
            <Header1 />
			<div className='main'>
				<Breadcrumb data={data} image={data.title_background}/>
                {data.layout=="wide_full_page" &&
					<FullWidth content={data.content} />                    
				}
				{data.layout=="full_page" &&
					<FullWidth content={data.content} />
				}
                {data.layout=="default" &&
				<section className='default about-p pt-120 pb-120 p-relative fix'>
					<div className="container">
					<div className="animations-02"><img src="https://demo.xpansion.com.au/bellfield5/img/bg/an-img-02.png" alt="contact-bg-an-01" /></div>
						<FullWidth content={data.content} />
                        <PhotoAlbum photos={data.photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                        <Lightbox
                            slides={data.photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Video]}
							video={{
								controls,
								playsInline,
								autoPlay,
								loop,
								muted,
								disablePictureInPicture,
								disableRemotePlayback,
								controlsList,
								crossOrigin,
								preload,
							  }}
                        />
					</div>
				</section>
				}
				{data.layout=="page_left_sidebar" &&
					<section className='default about-p pt-120 pb-120 p-relative fix'>
						<div className="container">
							<div className='row'>
                                <div class="col-md-3">
                                    <aside className='sidebar-widget' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.sidebar)}}></aside>
                                </div>
                                <div className="col-md-9">
								    <Content content={data.content} />
                                    <PhotoAlbum photos={data.photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                                    <Lightbox
                                        slides={data.photos}
                                        open={index >= 0}
                                        index={index}
                                        close={() => setIndex(-1)}
                                        // enable optional lightbox plugins
                                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                    />
                                </div>
							</div>
						</div>
					</section>
				}
				{data.layout=="page_right_sidebar" &&
					<section className='default about-p pt-120 pb-120 p-relative fix'>
						<div className="container">
							<div className='row'>
                                <div className="col-md-9">
                                    <Content htmldata={data.content} />
                                    <PhotoAlbum photos={data.photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                                    <Lightbox
                                        slides={data.photos}
                                        open={index >= 0}
                                        index={index}
                                        close={() => setIndex(-1)}
                                        // enable optional lightbox plugins
                                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                    />
                                </div>
                                <div class="col-md-3">
                                    <aside className='sidebar-widget' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.sidebar)}}></aside>
                                </div>
							</div>
						</div>
					</section>
				}				
			</div>
		</div>
	);
}

export default Gallery;