const Breadcrumb = ({ data , image }) => {
    const headerStyle={
        backgroundImage: `url(${image})`,
        backgroundSize: "cover"    
    }
    return (
        <section className="breadcrumb-area d-flex  p-relative align-items-center" style={headerStyle}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-lg-12">
                        <div className="breadcrumb-wrap text-left">
                            <div className="breadcrumb-title">
                                <h2>{data.title}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="breadcrumb-wrap2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{data.title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumb;