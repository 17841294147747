import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

import './css/animate.min.css';
import './css/magnific-popup.css';
import './fontawesome/css/all.min.css';
import './css/dripicons.css';
import './css/slick.css';
import './css/meanmenu.css';
import './css/default.css';
import './css/style.css';
import './css/responsive.css';
import '../src/App.css'

import Markup from './markup/markup';

function App() {
  return (
    <div className="App">
      <div id="preloader">
        <div className="preloader">
          <span></span>
          <span></span>
        </div>
      </div>
      <Markup />
    </div>
  );
}

export default App;
