import React, { Component,useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Header1 from '../Layout/Header1';
import Breadcrumb from '../Layout/Breadcrumb';
import FullWidth from '../Layout/FullWidth';
import PageWithSideBar from '../Layout/PageWithSideBar';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';
import getBaseUrl from '../../base/htttp';
import init from '../../js/main';
import PageNotFound from '../Errors/PageNotFound'

function CategoryBlog() {    
	const [data, setData] = useState("Loading...");
	const [statusCode, setStatusCode] = useState();
    const pageSlug = useParams();
	const getData = () => {
		fetch(getBaseUrl()+"category/"+pageSlug.slug)    
			.then(async (response) => {
				if (response.ok) {
				  return response.json()
				} else if(response.status === 404) {
					setStatusCode(response.status)
					return "";
				} else {
				  console.log('some other error: ' + response.status)
				}
			})
			.then(async (result) => {
				if(result!="" && result.success){
					setData(result.data);
					setTimeout(function() {
						init();
					}.bind(this), 400);												
				}
			})
	}

	useEffect(() => {
		getData();
	}, []);
	if (statusCode === 404) {
		return <PageNotFound />
	}
	return (        
		<div>			
            <Header1 />
			<div className='main'>
				<Breadcrumb data={data} image={data.title_background}/>
				{data.layout=="wide_full_page" &&
					<FullWidth content={data.content} />
				}
				{data.layout=="full_page" &&
					<FullWidth content={data.content} />
				}
				{data.layout=="default" &&
				<section className='default about-p pt-120 pb-120 p-relative fix'>
					<div className="container">
					<div class="animations-02"><img src="https://demo.xpansion.com.au/bellfield5/img/bg/an-img-02.png" alt="contact-bg-an-01" /></div>
						<FullWidth content={data.content} />
					</div>
				</section>
				}
				{data.layout=="page_left_sidebar" &&
					<section className='default about-p pt-120 pb-120 p-relative fix'>
						<div className="container">
							<div className='row'>
								<Sidebar htmldata={data.sidebar} />
								<PageWithSideBar content={data.content} />
							</div>
						</div>
					</section>
				}
				{data.layout=="page_right_sidebar" &&
					<section className='default about-p pt-120 pb-120 p-relative fix'>
						<div className="container">
							<div className='row'>
								<PageWithSideBar content={data.content} />
								<Sidebar htmldata={data.sidebar} />
							</div>
						</div>
					</section>
				}
				<Footer />
			</div>
		</div>
	);
}

export default CategoryBlog;