import * as DOMPurify from 'dompurify';

const Sidebar = ({ htmldata }) => {
    return (
        <div class="col-md-3">
            <aside className='sidebar-widget' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmldata)}}></aside>
        </div>
    );
};

export default Sidebar;